import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from 'styled-components'
import { Container, Card, Col, Row} from "react-bootstrap"

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  width: 100%;
  padding: 1rem;
  margin-bottom: 2rem;
  h2 {
    padding: 1rem;
    margin: 1rem;
    font-weight: bold;
  }
  a {
    transition: 0.33s;
  }
  b{
    margin-bottom: 1rem;
  }
  @media(max-width: 768px) {
    min-height: 250px;
    margin-bottom: 0;
  }
`

const Posts = () => {
  const data = useStaticQuery(graphql`
    query PostsQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {type: {eq: "blog"}}}) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 1200, maxHeight: 350, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges
  return (
    <Container>
      <Row>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <Col md={12} className="post" key={node.fields.slug}>
            <Link to={"/blog" + node.fields.slug}>
              <Card className="pt-2 hoverCard" bg="secondary" style={{ color: 'var(--textNormal)', }}>
                <h2>
                  {title}  
                </h2>
                <b style={{ color: 'var(--textNormal)'}}>{node.frontmatter.date}</b>
                <StyledBackgroundImage fluid={node.frontmatter.image.childImageSharp.fluid}>
                </StyledBackgroundImage>
              </Card>
              </Link>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

export default Posts
